import { ref, watch, computed, reactive } from '@vue/composition-api'
import store, {globalStore} from '@/store'
import { title } from '@core/utils/filter'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const reviews = ref([])
const filteredReviews = ref([])

export default function useUserReview() {
  // Use toast
  const toast = useToast()

  const refReviewTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'rating', sortable: true },
    { key: 'feedback', sortable: true },
    { key: 'user', label: 'Customer' },
    { key: 'trip.id', label: 'Trip Id' },
  ]
  const perPage = ref(10)
  const totalReviews = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refReviewTable.value ? refReviewTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReviews.value,
    }
  })

  const refetchData = () => {
    refReviewTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = reviews.value.filter(
    review => (review.feedback.toLowerCase().includes(queryLowered)) || review.rider.toLowerCase().includes(queryLowered))
    
    filteredReviews.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalReviews.value = filteredData.length
    refetchData()
  })

  const fetchReviews = (ctx) => {
    if(reviews.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.USER_REVIEW)
      .then(({data}) => {
        data.forEach((d) => {
          d.user = `${d.trip?.user?.profile?.fullName}`
        });
        reviews.value = data.sort((a, b) => a.updatedAt - b.updatedAt);
        filteredReviews.value = paginateArray(reviews.value, perPage.value, currentPage.value)
        totalReviews.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching reviews',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchReviews,
    tableColumns,
    perPage,
    currentPage,
    totalReviews,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReviewTable,
    refetchData,
    reviews: computed(() => filteredReviews.value)
  }
}
